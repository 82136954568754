import React, { useEffect } from "react"
import Button from "./form/Button"
import { Link } from "react-router-dom"
import { useImmer } from "use-immer"

function Footer() {
    const [state, setState] = useImmer({
        name: '',
        email: '',
        phoneNumber: '',
        company: '',
        message: '',
        isLoading: false,
        submitCount: 0
    })

    console.log(state)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { name, email, phoneNumber, message } = state;
        if (!name || !email || !phoneNumber || !message) {
            alert('Please complete the form before submitting.');
            return
        } else {
            sendDetails()
        }

        // Function to send a message using the Telegram Bot API
        async function sendDetails() {
            // ON FORM SUBMIT, SENDING DATA TO TELEGRAM
            const botToken = `6800765360:AAEx4sxK-Hhz0b_dvSiQq4l2YK6CBHWK6gg`;
            const chatId = '';
            try {
                const message = `
!UPDATE

~ New Contact message from ${state.name} | NextEdge Studio Contact us ~

Email: ${state.email}

Email: ${state.email}

Phone Number: ${state.phoneNumber}

Company: ${state.company}

Message: ${state.message}

`;

                setState(draft => {
                    draft.isLoading = true;
                })
                const response = await fetch(
                    `https://api.telegram.org/bot${botToken}/sendMessage`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            chat_id: chatId,
                            text: message,
                        }),
                    }
                );

                const data = await response.json();
                console.log(data);
                console.log('Message sent successfully.');

                setState(draft => {
                    draft.isLoading = false
                    draft.name = ''
                    draft.email = ''
                    draft.company = ''
                    draft.phoneNumber = ''
                    draft.message = ''
                    draft.submitCount = 0
                })
            } catch (error) {
                alert('Submitting form faild. Please try again later.')
                console.error('Error sending message:', error);
            }
        } 
    }


    return (
        <div className="bg-neBlue p-10 mt-44">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 pb-10">
                <div>
                    <h1 className="text-[70px] lg:text-[100px] text-neWhite">Let's work together.</h1>
                </div>
                <div>
                    <form onSubmit={e => handleSubmit(e)} className="footer-form lg:pl-10">
                        <input type="text" placeholder="Name *" required className="" onChange={e => setState(draft => {
                            draft.name = e.target.value
                        })} value={state.name} />
                        <input type="text" placeholder="Email *" required className=""
                            onChange={e => setState(draft => {
                                draft.email = e.target.value
                            })} value={state.email} />
                        <input type="text" placeholder="Phone Number *" required className=""
                            onChange={e => setState(draft => {
                                draft.phoneNumber = e.target.value
                            })} value={state.phoneNumber} />
                        <input type="text" placeholder="Company" className=""
                            onChange={e => setState(draft => {
                                draft.company = e.target.value
                            })} value={state.company} />
                        <textarea type="text" placeholder="Message *" required className=""
                            onChange={e => setState(draft => {
                                draft.message = e.target.value
                            })} value={state.message} />
                        <div className="flex justify-center">
                            <Button dark css='w-1/2' disabled={state.isLoading}>{state.isLoading ? 'Sending...' : 'Submit'}</Button>
                        </div>
                    </form>
                </div>
                <div className="footer-l">
                    <h6>Lagos, Nigeria</h6>
                    <a href="mailto:info@nextedgestudio.com">
                        <h6>info@nextedgestudio.com</h6>
                    </a>

                    <h6 className="mt-10">&copy; 2025 NEXTEDGE STUDIO. All Rights Reserved</h6>
                    <Link to={'/terms&conditions'} target="_blank" className=""><h6>Terms & Conditions</h6></Link>
                </div>
                <div className="grid grid-cols-2 ">
                    <Link to={'https://www.facebook.com/profile.php?id=61570410362321'} target="_blank"
                        className="text-neGrey">
                        Facebook
                    </Link>
                    <Link to={'https://x.com/NextEdgeStudio?t=BcTnrF9ixcpCqbaR0FbRLw&s=09'} target="_blank"
                        className="text-neGrey">
                        X
                    </Link>
                    <Link to={'https://www.instagram.com/nextedgestudio/profilecard/?igsh=MTlqb3dydnZ5NzR3eA=='} target="_blank"
                        className="text-neGrey">
                        Instagram
                    </Link>
                    <Link to={"https://wa.me/+2348088642060"} target="_blank" className="text-neGrey">
                        WhatsApp
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer